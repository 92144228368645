@import "./colors.css";

.Graph {
  grid-area: graph;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: center;
  margin: 0 20px 20px 0;
}

.GraphStatus {
  display: flex;
  align-items: center;
  position: absolute;
  height: 20px;
  bottom: 0;
  right: 0;
  color: var(--color-text-secondary);
}

.GraphStatus_error {
  color: var(--color-error);
}

.GraphStatusMessage {
  margin-right: 5px;
}

.GraphCheckmarkIcon {
  color: var(--color-alert-text-info);
}

.GraphLoadingIcon {
  composes: LoadingIcon from "./LoadingIcon.module.css";
  width: 20px;
  height: 20px;
}

.WelcomeAlert {
  position: absolute;
  max-width: 800px;
}

.WelcomeAlertParagraph {
  margin-bottom: 0;
}

@media screen and (max-width: 750px) {
  .Graph {
    margin-left: 20px;
  }

  .WelcomeAlert {
    position: relative;
  }
}
