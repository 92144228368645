@import "./colors.css";

.Fieldset {
  box-sizing: content-box; /* So that the scrollbar isn't included in the margin */
  padding: 15px;
  margin: 0 0 20px 0;
  border: none;
  background-color: var(--color-fieldset-background);
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.2);
}

.Fieldset:last-of-type {
  margin-bottom: 0;
}

.FieldsetHeader {
  margin: 0;
  font-size: 1em;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
