:root {
  --color-background: rgb(240, 240, 240);

  --color-error: rgb(199, 0, 0);
  --color-loading: rgb(255, 193, 7);

  --color-fieldset-background: rgb(255, 255, 255);
  --color-field-border: rgb(206, 212, 218);

  --color-text: rgb(51, 51, 51);
  --color-text-secondary: rgb(136, 136, 136);
  --color-text-fieldInput: rgb(73, 80, 87);
  --color-text-button-active: rgb(255, 255, 255);

  --color-button-background: rgba(0, 0, 0, 0.03);
  --color-button-border: rgb(230, 230, 230);
  --color-button-background-active: rgb(89, 159, 219);
  --color-button-border-active: rgb(46, 133, 209);

  --color-alert-background-info: rgb(212, 237, 218);
  --color-alert-border-info: rgb(195, 230, 203);
  --color-alert-text-info: rgb(21, 87, 36);

  --color-alert-background-warning: rgb(255, 243, 205);
  --color-alert-border-warning: rgb(255, 238, 186);
  --color-alert-text-warning: rgb(133, 100, 4);

  --color-graph-node-hover: rgb(0, 51, 102);
  --color-graph-tooltip-background: rgba(255, 255, 255, 0.95);
}
