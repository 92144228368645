.Sidebar {
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  width: 455px;
  /* The sidebar should fill the page height minus the header. */
  max-height: calc(100vh - var(--header-height) - (var(--header-vertical-margin) * 2));
}

@media screen and (max-width: 1100px) {
  .Sidebar {
    width: 300px;
  }
}

@media screen and (max-width: 750px) {
  .Sidebar {
    width: unset;
    max-height: unset;
  }
}

.SidebarFooter {
  font-size: 0.9em;
  margin: 10px 0;
  text-align: center;
}
