:root {
  --header-height: 47px;
  --header-vertical-margin: 15px;
  --header-horizontal-margin: 20px;
}

.Header {
  grid-area: header;
  display: flex;
  align-items: center;
  margin-top: var(--header-vertical-margin);
  margin-bottom: var(--header-vertical-margin);
  margin-left: var(--header-horizontal-margin);
  margin-right: var(--header-horizontal-margin);
  height: var(--header-height);
}

.HeaderTitle {
  font-weight: 500;
  font-size: 1.6em;
  margin: 10px 15px;
}

.RavelinLogo {
  width: 30px;
  height: 30px;
}
