@import "./colors.css";

.Alert {
  display: flex;
  align-items: flex-start;
  padding: 15px;
  border: 1px solid;
}

.Alert_info {
  background-color: var(--color-alert-background-info);
  border-color: var(--color-alert-border-info);
  color: var(--color-alert-text-info);
}

.Alert_warning {
  background-color: var(--color-alert-background-warning);
  border-color: var(--color-alert-border-warning);
  color: var(--color-alert-text-warning);
}

.AlertContent {
  flex: 1; /* Main content should grow to fill the available space */
}

.AlertDismissButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 10px;
}
