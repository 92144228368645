@import "./colors.css";

.VenueSearch {
  flex: 1;
  overflow: auto;
}

.VenueSearchResults {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 10px 0;
  padding: 0;
}

.VenueSearchResultListItem {
  border: 1px solid var(--color-button-border);
  border-bottom: 1px solid white;
  transition: background-color 0.1s;
}

.VenueSearchResultListItem:first-of-type {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.VenueSearchResultListItem:last-of-type {
  border-bottom-color: var(--color-button-border);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.VenueSearchResultListItem:focus,
.VenueSearchResultListItem:hover {
  background-color: var(--color-button-background);
  border-bottom-color: var(--color-button-background);
}

.VenueSearchResultListItem_selected:focus,
.VenueSearchResultListItem_selected:hover,
.VenueSearchResultListItem_selected {
  background-color: var(--color-button-background-active);
  border-color: var(--color-button-border-active);
  color: var(--color-text-button-active);
}

.VenueSearchResultButton {
  padding: 10px;
  margin: 0;
  min-height: 50px;
  width: 100%;
  background: none;
  border: none;
  color: inherit;
  font-size: 1em;
  text-align: left;
  cursor: pointer;
}

.SearchResultName {
  font-size: 1em;
  font-weight: 500;
  margin: 0 0 5px 0;
}

.SearchResultAddress {
  font-size: 0.8em;
  color: var(--color-text-secondary);
  margin: 0;
}

.VenueSearchResultListItem_selected .SearchResultAddress {
  color: var(--color-text-button-active);
}

.NoResults {
  margin-top: 15px;
}

.ShowAllResultsButton {
  background: none;
  border: none;
  width: 100%;
  font-size: 0.9em;
  color: var(--color-text-secondary);
  cursor: pointer;
}

.ShowAllResultsButton:hover {
  text-decoration: underline;
}
