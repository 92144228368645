@import "./colors.css";

.Field {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.FieldLabel {
  font-size: 1em;
  margin-bottom: 5px;
}

.FieldInput {
  flex: 1;
  font-size: 1em;
  line-height: 1.5;
  padding: 0.375em 0.75em;
  border: 1px solid var(--color-field-border);
  color: var(--color-text-fieldInput);
  text-overflow: ellipsis;
}

.FieldInput_error {
  border-color: var(--color-error);
}

.FieldError {
  font-size: 0.9em;
  margin: 5px 0;
  color: var(--color-error);
}

.PasswordFieldInputContainer,
.ProgressFieldInputContainer {
  position: relative;
  display: flex;
}

.PasswordFieldInput,
.ProgressFieldInput {
  padding-right: 30px;
}

.PasswordFieldInputToggle {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 5px;
  border: none;
  background: none;
  padding: 0;
  margin: auto 0;
  width: 20px;
  color: rgb(180, 180, 180);
  cursor: pointer;
}

.FieldLoadingIcon {
  composes: LoadingIcon from "./LoadingIcon.module.css";
  position: absolute;
  top: 0;
  right: 7px;
  bottom: 0;
  width: 14px;
  height: 14px;
}
