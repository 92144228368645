.App {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "header header"
    "sidebar graph";
  height: 100vh;
  width: 100vw;
}

@media screen and (max-width: 750px) {
  .App {
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
      "header"
      "graph"
      "sidebar";
    width: unset;
  }
}
