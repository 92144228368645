@import "./colors.css";

.D3Graph {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.D3GraphNode:hover {
  stroke: var(--color-graph-node-hover);
  transition: stroke 0.2s;
}

.RestaurantIcon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto; /* Center vertically and horizontally */
  width: 80%;
  height: 80%;
  color: rgb(235, 235, 235);
  transition: color 0.2s;
}

@media screen and (min-width: 750px) {
  /* Darken the icon when there is no venue showing */
  .RestaurantIcon_withNoVenue {
    color: rgb(220, 220, 220);
  }
}

@media screen and (max-width: 750px) {
  /* Lock the graph height in mobile view */
  .D3GraphContainer_withVenue {
    height: 500px;
  }
}

/* These tooltip styles are global because they're used by tippy for the graph's hover tooltip */
:global(.venueTooltip) {
  background-color: var(--color-graph-tooltip-background);
  padding: 10px;
  border-radius: 3px;
}

:global(.venueTooltipParagraph) {
  margin: 2px 0;
}
