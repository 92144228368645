@import "./colors.css";

.LoadingIcon {
  display: inline-block;
  vertical-align: text-bottom;
  border: 3px solid var(--color-loading);
  border-right-color: transparent;
  border-radius: 50%;
  margin: auto 0;
  width: 14px;
  height: 14px;
  animation: spin 0.75s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
